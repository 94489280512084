<template>
  <AppLayout>
    <div class="md:flex md:items-center md:justify-between px-6 sm:px-10">
      <div class="min-w-0 flex-1">
        <div class="hero-structures max-w-[1520px] mx-auto">
          <h1 class="title1 text-5xl mb-5 !mt-20 text-white">
            {{ steps[$route.params.idMilestone - 1].name }}
          </h1>
          <h2 class="text-2xl text-indigo-600 mb-10 text-white">
            Date à définir
          </h2>
        </div>
      </div>
    </div>
    <div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-[1520px] bg-white pt-1 sm:pt-8 rounded-xl" id="milestone">
      <div class="mx-auto max-w-[1100px] mt-20">
        <router-link class="" to="/tableau-de-bord">
          <div class="flex items-center mb-10 hover:text-indigo-700">
            <ChevronLeftIcon class="h-8 w-8 flex-shrink-0 text-indigo-600 ml-1 sm:-ml-2" aria-hidden="true" /> Retour
            Parcours
            Fest
          </div>
        </router-link>
        <div class="mb-10" id="intro">
          <div class="px-4 sm:px-0">
            <h3 class="text-xl font-semibold leading-7 text-indigo-700">Présentation</h3>
            <p class="mt-1 max-w-2xl text-base leading-7 text-gray-500">Présentation de l'étape et ses enjeux</p>
          </div>
          <div class="mt-6 border-t border-gray-100">
            <dl class="divide-y divide-gray-100">
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-base font-medium leading-7 text-gray-900">Rôles attendus</dt>
                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0">
                  <span class="roleBadge bg-role6 mr-2"
                    v-if="steps[$route.params.idMilestone - 1].roles.includes('Encadrement')">Encadrement</span>
                  <span class="roleBadge bg-role3 mr-2"
                    v-if="steps[$route.params.idMilestone - 1].roles.includes('Formateur•rice métier')">Formateur•rice
                    métier</span><span class="roleBadge bg-role4 mr-2"
                    v-if="steps[$route.params.idMilestone - 1].roles.includes('Formateur·trice référent·e')">Formateur•rice
                    référent</span><span class="roleBadge bg-role2"
                    v-if="steps[$route.params.idMilestone - 1].roles.includes('Apprenant·e')">Apprenant•e</span>
                </dd>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-base font-medium leading-7 text-gray-900">Date</dt>
                <dd class="mt-1 flex text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div class="flex-grow">
                    <div class="flex w-full flex-none gap-x-2">
                      <div class="flex-none">
                        <span class="sr-only">Due date</span>
                        <CalendarDaysIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <div class="text-base leading-7 text-gray-500">
                        <time datetime="2023-01-31">Date à définir</time>
                      </div>
                    </div>
                  </div>
                  <span class="ml-4 flex-shrink-0" :class="'pointer-events-none opacity-50 '">
                    <button type="button"
                      class="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500">Modifier</button>
                  </span>
                </dd>

              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-base font-medium leading- text-gray-900">Enjeu, objectif de cette étape</dt>
                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div v-html="steps[$route.params.idMilestone - 1].goals"></div>
                </dd>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                v-if="steps[$route.params.idMilestone - 1].expectations">
                <dt class="text-base font-medium leading-7 text-gray-900">Attentes</dt>
                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div v-html="steps[$route.params.idMilestone - 1].expectations"></div>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="mb-32" id="before" v-for="todo in steps[$route.params.idMilestone - 1].todo" :key="todo.help">
          <div class="px-4 sm:px-0">
            <h3 class="text-xl font-semibold leading-7  text-indigo-700">
              {{ todo.titlePart }}
            </h3>
          </div>
          <div class="mt-6 border-t border-gray-100">
            <dl class="">

              <div class="px-4 py-6 sm:px-0 mx-auto max-w-[900px]">
                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ todo.help }}
                </dd>
              </div>
              <div class="px-4 py-6 sm:px-0 mx-auto max-w-[900px]">
                <dd class="mt-2 text-base text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                    <li class="flex items-center justify-between py-4 pl-4 pr-5 text-base leading-7"
                      v-for="file in todo.ressources" :key="file">
                      <div class="flex w-0 flex-1 items-center">
                        <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <div class="ml-4 flex min-w-0 flex-1 gap-2">
                          <span class="truncate font-medium">{{ file.name }}</span>
                        </div>
                      </div>
                      <div class="ml-4 flex-shrink-0" v-if="file.href">
                        <a :href="file.href" target="_blank"
                          class="font-medium text-indigo-600 hover:text-indigo-500">Voir</a>
                      </div>
                    </li>

                  </ul>
                </dd>
              </div>
              <div class="bg-indigo-50 mt-10 shadow sm:rounded-lg" v-if="todo.actions">
                <div class="px-4 py-5 sm:p-6">
                  <div class="">
                    <div class="flex items-center gap-4">
                      <div>
                        <h3 class="text-base font-semibold leading-7  text-indigo-700">{{ todo.titleTodo }}</h3>
                        <div class="mt-2 text-base text-gray-500">
                          <div v-html="todo.description"></div>
                        </div>
                      </div>
                      <div class="ml-auto"> <button type="button" class="btn-read whitespace-nowrap"
                          :class="'pointer-events-none opacity-50 '">Télécharger
                          le modèle</button></div>
                    </div>

                    <div class="mt-10 sm:mt-5 sm:flex sm:flex-shrink-0 sm:items-center">
                      <button type="button" :class="'pointer-events-none opacity-50 '"
                        class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Rédiger
                        en ligne</button>
                      <button type="button" :class="'pointer-events-none opacity-50 '"
                        class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Déposer
                        un fichier</button>
                    </div>
                    <div v-if="route.params.idMilestone === '2'">
                      <h3 class="text-base font-semibold leading-7  text-indigo-700 mt-10 mb-5">Programmer les 4
                        situations
                      </h3>
                      <div class="flex">
                        <div class=""> <button type="button" class="btn-read flex-col">
                            <div class="text-indigo-600">Situation 1</div>
                            <div>Date indéfinie</div>
                          </button>
                        </div>
                        <div class=""> <button type="button" class="btn-read flex-col">
                            <div class="text-indigo-600">Situation 2</div>
                            <div>Date indéfinie</div>
                          </button>
                        </div>
                        <div class=""> <button type="button" class="btn-read flex-col">
                            <div class="text-indigo-600">Situation 3</div>
                            <div>Date indéfinie</div>
                          </button>
                        </div>
                        <div class=""> <button type="button" class="btn-read flex-col">
                            <div class="text-indigo-600">Situation 4</div>
                            <div>Date indéfinie</div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import AppLayout from '@/layouts/AppLayout.vue';
import client from '@/api/client';
import { UserCircleIcon } from '@heroicons/vue/24/solid';
import StructureCard from '@/components/structures/StructureCard.vue';
import { PaperClipIcon, EllipsisVerticalIcon, CalendarDaysIcon, CheckIcon } from '@heroicons/vue/20/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import UploadFile from '@/components/files/UploadFile.vue';
import { ChevronLeftIcon, HomeIcon } from '@heroicons/vue/20/solid'

const steps = [
  {
    id: '1',
    name: 'Rendez-vous 1',
    roles: ['Encadrement', 'Formateur·trice référent·e'],
    goals: '<p>Le but ici est de présenter la FEST à l’encadrement de la structure (qui peut être, ou non, le formateur métier) pour que la FEST puisse se mettre en place <b class="bold">dans les bonnes conditions</b>. Par ailleurs, il s’agit de faire prendre conscience des « exigences » de la FEST, mais aussi de <b>son intérêt pour l’apprenant, le formateur métier et la structure.</b></p>',
    expectations: '<p><b>Pour l’encadrement</b>, comprendre ce que la FEST exige et quels intérêt cette modalité peut présenter pour la structure (par exemple pour l’intégration de nouveaux salariés, le questionnement des pratiques, …).<br></p><p><b> Pour le formateur référent</b>, vérifier que la structure donne les moyens de mettre en place la FEST, mais aussi percevoir son positionnement par rapport à la dynamique FEST.',
    todo: [
      {
        titlePart: 'Documents supports',
        help: 'Vous pouvez utiliser les documents suivants pour animer le rendez-vous, expliquer la Fest, le déroulé des situations de travail...',
        titleTodo: 'Ce que l\'on retient de l\'entretien',
        description: 'Décisions prises, éléments à retenir, synthèse des échanges, ...',
        ressources: [
          { name: 'Document support au rdv 1', href: '' },
          { name: 'Fiche référence de l’activité « entrer en relation avec »', href: '' }],
        actions: [
          { name: 'Déposer', href: '' },
          { name: 'Rédiger en ligne', href: '' }
        ]
      }]
  },
  {
    id: '2',
    name: 'Rendez-vous 2',
    roles: ['Formateur·trice référent·e', 'Formateur•rice métier'],
    goals: '<p>Le but ici est de préparer la mise en place des FEST pour l’apprenant : positionnement de l’apprenant par rapport à la fiche référence de l’activité, à partir de ça identification des situations qui pourraient être pertinentes à transformer en FEST, mais aussi comment aménager ces situations : permettre le droit à l’essai et à l’erreur, permettre au formateur métier et référent d’observer l’apprenant sans intervenir, prévenir les collègues, voir s’il faut prévenir le public, … Et toutes les autres questions à se poser./p>',
    expectations: '<p><b>Pour le formateur métier</b>, comprendre ce qui est attendu de lui (la FEST n’est pas une évaluation de l’apprenant) et comment jouer ce rôle (via les entretiens réflexifs). Mais aussi planifier les mises en situation de FEST pour pouvoir organiser l’activité de la structure en fonction.</p><p><b>Pour le formateur référent</b>, permettre à l’apprenant de se positionner, définir les situations de travail qui seront aménagées en mises en situation FEST, planifier les 4 mises en situation, encourager l’apprenant à être dans une posture réflexive (ce n’est pas une évaluation).</p><p><b>Pour l’apprenant</b>, voir l’intérêt de la FEST pour son parcours de formation, se situer par rapport à la fiche référence de l’activité, identifier des situations de travail qui vont lui permettre de « tester des choses ».</p>',
    todo: [
      {
        titlePart: 'Documents supports',
        help: 'Vous pouvez utiliser les documents suivants pour animer le rendez-vous, expliquer la Fest, le déroulé des situations de travail...',
        titleTodo: 'Ce que l\'on retient de l\'entretien',
        description: 'Éléments à retenir, synthèse des échanges, notamment sur les situations choisies : pourquoi ces situations ont été choisies et comment elles vont être aménagées. ',
        ressources: [
          { name: 'Document support au rdv 1', href: '' },
          { name: 'Fiche référence de l’activité « entrer en relation avec »', href: '' },
          { name: 'Fiche de positionnement de l’apprenant', href: '' }
        ],
        actions: [
          { name: 'Déposer', href: '' },
          { name: 'Rédiger en ligne', href: '' }
        ]
      }]
  },
  {
    id: '3',
    name: 'Situation de travail 1',
    roles: ['Formateur•rice métier', 'Apprenant·e', 'Formateur·trice référent·e'],
    goals: '<p>Le but est de permettre à l’apprenant de faire et de réfléchir à comment il s’y est pris (la fameuse réflexivité). Pour ça, on commence par un entretien en amont, ensuite il est mis en situation, puis on réalise l’entretien en aval.</p>',
    expectations: '<p><b>Pour le formateur référent</b>, aider l’apprenant à mettre en mot sa pratique, à lui faire percevoir ce qui est solide ou plus fragile dans ce qu’il fait.</p><p><b>Pour le formateur métier</b>, voir comment l’apprenant « s’y prend », voir le sens qu’il met derrière ses actes. Mais aussi identifier d’autres manières de faire possibles, voire des choses à modifier dans l’organisation pour améliorer ce qui peut être fait, … </p><p><b>Pour l’apprenant</b>, agir et mettre en mots ses actes, ses intentions et ses réflexions, via la réflexivité. Mais aussi échanger avec le formateur métier sur les différentes façons de faire, sur l’organisation de la structure qui permet ou qui contraint ces façons de faire, …</p>',
    todo: [{
      titlePart: 'L\'entretien réflexif amont',
      help: 'L’enjeu de l’entretien réflexif amont est de permettre à l’apprenant de se projeter dans la situation, pour qu’il identifie ce qu’il imagine qu’il va se jouer. Pour cela, on relit avec lui la fiche référence de l’activité et cela sert de support à un échange sur les manières de faire (« habituelles » ou qui vont être testées cette fois-ci). On le questionne sur comment il s’est préparé, ce qu’il identifie comme enjeux pour la situation, comment il imagine mener cette situation, …',
      titleTodo: 'Entretien réflexif amont',
      description: 'L’enjeu de l’entretien réflexif amont est de permettre à l’apprenant de se projeter dans la situation, pour qu’il identifie ce qu’il imagine qu’il va se jouer. Pour cela, on relit avec lui la fiche référence de l’activité et cela sert de support à un échange sur les manières de faire (« habituelles » ou qui vont être testées cette fois-ci). On le questionne sur comment il s’est préparé, ce qu’il identifie comme enjeux pour la situation, comment il imagine mener cette situation, …',
      ressources: [{ name: 'Fiche référence de l’activité « entrer en relation avec »', href: '' }],
    },
    {
      titlePart: 'L\'entretien réflexif aval',
      help: 'L’enjeu de l’entretien réflexif aval est de permettre à l’apprenant de mettre en mots la partie « immergée » de l’iceberg : dans toute action, il y a une partie émergée, ce sont les actes, qui sont visibles et observables, et il y a une partie immergée, c’est-à-dire tout ce qui se passe dans la tête de la personne au moment où elle réalise cet acte. C’est cela que l’on va chercher : les réflexions qu’elle se fait pendant la situation, l’analyse qu’elle en fait en même temps qu’elle se déroule, les perceptions qui vont « intuitivement » lui signifier qu’il est en train de se passer quelque chose d’important, les « micro-décisions » prises pour adapter son action, bref tout ce qui permet à un bon professionnel d’agir avec compétence.',
      titleTodo: 'Document à rédiger ',
      description: 'Vous pouvez, soit déposer le positionnement papier rempli de l’apprenant (scan ou photo), soit rédiger en ligne directement ce positionnement',
      ressources: [{ name: 'Fiche de positionnement de l’apprenant', href: '' }],
      actions: [
        { name: 'Déposer', href: '' },
        { name: 'Rédiger en ligne', href: '' }
      ]
    }]
  },
  {
    id: '4',
    name: 'Situation de travail 2',
    roles: ['Formateur•rice métier', 'Apprenant·e'],
    goals: '<p>Le but est de permettre à l’apprenant de faire et de réfléchir à comment il s’y est pris (la fameuse réflexivité). Pour ça, on commence par un entretien en amont, ensuite il est mis en situation, puis on réalise l’entretien en aval.</p>',
    expectations: '<p><b>Pour le formateur métier</b>, voir comment l’apprenant « s’y prend », voir le sens qu’il met derrière ses actes, l’aider à mettre en mot sa pratique. Mais aussi identifier d’autres manières de faire possibles, voire des choses à modifier dans l’organisation pour améliorer ce qui peut être fait, …</p><p><b>Pour l’apprenant</b>, agir et mettre en mots ses actes, ses intentions et ses réflexions, via la réflexivité. Mais aussi échanger avec le formateur métier sur les différentes façons de faire, sur l’organisation de la structure qui permet ou qui contraint des façons de faire, …</p>',
    todo: [{
      titlePart: 'L\'entretien réflexif amont',
      help: 'L’enjeu de l’entretien réflexif amont est de permettre à l’apprenant de se projeter dans la situation, pour qu’il identifie ce qu’il imagine qu’il va se jouer. Pour cela, on relit avec lui la fiche référence de l’activité et cela sert de support à un échange sur les manières de faire (« habituelles » ou qui vont être testées cette fois-ci). On le questionne sur comment il s’est préparé, ce qu’il identifie comme enjeux pour la situation, comment il imagine mener cette situation, …',
      titleTodo: 'Entretien réflexif amont',
      description: 'L’enjeu de l’entretien réflexif amont est de permettre à l’apprenant de se projeter dans la situation, pour qu’il identifie ce qu’il imagine qu’il va se jouer. Pour cela, on relit avec lui la fiche référence de l’activité et cela sert de support à un échange sur les manières de faire (« habituelles » ou qui vont être testées cette fois-ci). On le questionne sur comment il s’est préparé, ce qu’il identifie comme enjeux pour la situation, comment il imagine mener cette situation, …',
      ressources: [{ name: 'Fiche référence de l’activité « entrer en relation avec »', href: '' }],
    },
    {
      titlePart: 'L\'entretien réflexif aval',
      help: 'L’enjeu de l’entretien réflexif aval est de permettre à l’apprenant de mettre en mots la partie « immergée » de l’iceberg : dans toute action, il y a une partie émergée, ce sont les actes, qui sont visibles et observables, et il y a une partie immergée, c’est-à-dire tout ce qui se passe dans la tête de la personne au moment où elle réalise cet acte. C’est cela que l’on va chercher : les réflexions qu’elle se fait pendant la situation, l’analyse qu’elle en fait en même temps qu’elle se déroule, les perceptions qui vont « intuitivement » lui signifier qu’il est en train de se passer quelque chose d’important, les « micro-décisions » prises pour adapter son action, bref tout ce qui permet à un bon professionnel d’agir avec compétence.',
      titleTodo: 'Document à rédiger ',
      description: 'Vous pouvez, soit déposer le positionnement papier rempli de l’apprenant (scan ou photo), soit rédiger en ligne directement ce positionnement',
      ressources: [{ name: 'Fiche de positionnement de l’apprenant', href: '' }],
      actions: [
        { name: 'Déposer', href: '' },
        { name: 'Rédiger en ligne', href: '' }
      ]
    }]
  },
  {
    id: '5',
    name: 'Situation de travail 3',
    roles: ['Formateur•rice métier', 'Apprenant·e'],
    goals: '<p>Le but est de permettre à l’apprenant de faire et de réfléchir à comment il s’y est pris (la fameuse réflexivité). Pour ça, on commence par un entretien en amont, ensuite il est mis en situation, puis on réalise l’entretien en aval.</p>',
    expectations: '<p><b>Pour le formateur métier</b>, voir comment l’apprenant « s’y prend », voir le sens qu’il met derrière ses actes, l’aider à mettre en mot sa pratique. Mais aussi identifier d’autres manières de faire possibles, voire des choses à modifier dans l’organisation pour améliorer ce qui peut être fait, …</p><p><b>Pour l’apprenant</b>, agir et mettre en mots ses actes, ses intentions et ses réflexions, via la réflexivité. Mais aussi échanger avec le formateur métier sur les différentes façons de faire, sur l’organisation de la structure qui permet ou qui contraint des façons de faire, …</p>',
    todo: [{
      titlePart: 'L\'entretien réflexif amont',
      help: 'L’enjeu de l’entretien réflexif amont est de permettre à l’apprenant de se projeter dans la situation, pour qu’il identifie ce qu’il imagine qu’il va se jouer. Pour cela, on relit avec lui la fiche référence de l’activité et cela sert de support à un échange sur les manières de faire (« habituelles » ou qui vont être testées cette fois-ci). On le questionne sur comment il s’est préparé, ce qu’il identifie comme enjeux pour la situation, comment il imagine mener cette situation, …',
      titleTodo: 'Entretien réflexif amont',
      description: 'L’enjeu de l’entretien réflexif amont est de permettre à l’apprenant de se projeter dans la situation, pour qu’il identifie ce qu’il imagine qu’il va se jouer. Pour cela, on relit avec lui la fiche référence de l’activité et cela sert de support à un échange sur les manières de faire (« habituelles » ou qui vont être testées cette fois-ci). On le questionne sur comment il s’est préparé, ce qu’il identifie comme enjeux pour la situation, comment il imagine mener cette situation, …',
      ressources: [{ name: 'Fiche référence de l’activité « entrer en relation avec »', href: '' }],
    },
    {
      titlePart: 'L\'entretien réflexif aval',
      help: 'L’enjeu de l’entretien réflexif aval est de permettre à l’apprenant de mettre en mots la partie « immergée » de l’iceberg : dans toute action, il y a une partie émergée, ce sont les actes, qui sont visibles et observables, et il y a une partie immergée, c’est-à-dire tout ce qui se passe dans la tête de la personne au moment où elle réalise cet acte. C’est cela que l’on va chercher : les réflexions qu’elle se fait pendant la situation, l’analyse qu’elle en fait en même temps qu’elle se déroule, les perceptions qui vont « intuitivement » lui signifier qu’il est en train de se passer quelque chose d’important, les « micro-décisions » prises pour adapter son action, bref tout ce qui permet à un bon professionnel d’agir avec compétence.',
      titleTodo: 'Document à rédiger ',
      description: 'Vous pouvez, soit déposer le positionnement papier rempli de l’apprenant (scan ou photo), soit rédiger en ligne directement ce positionnement',
      ressources: [{ name: 'Questions types pour recueillir la description de l\'activité réelle', href: 'https://www.dropbox.com/scl/fi/t6ifoura9tfpy33o4ujzm/TF-RAFEST-Questions-types-entretiens-r-flexifs.pdf?rlkey=qb6qetbpo48vx4dcrb9ppk4ec&e=1&dl=0' },
      { name: 'Fiche de positionnement de l’apprenant', href: '' }
      ],
      actions: [
        { name: 'Déposer', href: '' },
        { name: 'Rédiger en ligne', href: '' }
      ]
    }]
  },
  {
    id: '6',
    name: 'Situation de travail 4',
    roles: ['Formateur•rice métier', 'Apprenant·e', 'Formateur·trice référent·e'],
    goals: '<p>Le but est de permettre à l’apprenant de faire et de réfléchir à comment il s’y est pris (la fameuse réflexivité). Pour ça, on commence par un entretien en amont, ensuite il est mis en situation, puis on réalise l’entretien en aval.</p>',
    expectations: '<p><b>Pour le formateur référent</b>, aider l’apprenant à mettre en mot sa pratique, à lui faire percevoir ce qui est solide ou plus fragile dans ce qu’il fait.</p><p><b>Pour le formateur métier</b>, voir comment l’apprenant « s’y prend », voir le sens qu’il met derrière ses actes. Mais aussi identifier d’autres manières de faire possibles, voire des choses à modifier dans l’organisation pour améliorer ce qui peut être fait, … </p><p><b>Pour l’apprenant</b>, agir et mettre en mots ses actes, ses intentions et ses réflexions, via la réflexivité. Mais aussi échanger avec le formateur métier sur les différentes façons de faire, sur l’organisation de la structure qui permet ou qui contraint ces façons de faire, …</p>',
    todo: [{
      titlePart: 'L\'entretien réflexif amont',
      help: 'L’enjeu de l’entretien réflexif amont est de permettre à l’apprenant de se projeter dans la situation, pour qu’il identifie ce qu’il imagine qu’il va se jouer. Pour cela, on relit avec lui la fiche référence de l’activité et cela sert de support à un échange sur les manières de faire (« habituelles » ou qui vont être testées cette fois-ci). On le questionne sur comment il s’est préparé, ce qu’il identifie comme enjeux pour la situation, comment il imagine mener cette situation, …',
      titleTodo: 'Entretien réflexif amont',
      description: 'L’enjeu de l’entretien réflexif amont est de permettre à l’apprenant de se projeter dans la situation, pour qu’il identifie ce qu’il imagine qu’il va se jouer. Pour cela, on relit avec lui la fiche référence de l’activité et cela sert de support à un échange sur les manières de faire (« habituelles » ou qui vont être testées cette fois-ci). On le questionne sur comment il s’est préparé, ce qu’il identifie comme enjeux pour la situation, comment il imagine mener cette situation, …',
      ressources: [{ name: 'Fiche référence de l’activité « entrer en relation avec »', href: '' }],
    },
    {
      titlePart: 'L\'entretien réflexif aval',
      help: 'L’enjeu de l’entretien réflexif aval est de permettre à l’apprenant de mettre en mots la partie « immergée » de l’iceberg : dans toute action, il y a une partie émergée, ce sont les actes, qui sont visibles et observables, et il y a une partie immergée, c’est-à-dire tout ce qui se passe dans la tête de la personne au moment où elle réalise cet acte. C’est cela que l’on va chercher : les réflexions qu’elle se fait pendant la situation, l’analyse qu’elle en fait en même temps qu’elle se déroule, les perceptions qui vont « intuitivement » lui signifier qu’il est en train de se passer quelque chose d’important, les « micro-décisions » prises pour adapter son action, bref tout ce qui permet à un bon professionnel d’agir avec compétence.',
      titleTodo: 'Document à rédiger ',
      description: 'Vous pouvez, soit déposer le positionnement papier rempli de l’apprenant (scan ou photo), soit rédiger en ligne directement ce positionnement',
      ressources: [{ name: 'Fiche de positionnement de l’apprenant', href: '' }],
      actions: [
        { name: 'Déposer', href: '' },
        { name: 'Rédiger en ligne', href: '' }
      ]
    }]
  },
  {
    id: '7',
    name: 'Bilan',
    roles: ['Formateur•rice métier', 'Apprenant·e', 'Formateur·trice référent·e'],
    goals: 'L\'enjeu du bilan est de permettre à l’apprenant et au formateur métier de mettre en mots ce qu’ils ont appris, compris, et modifié dans leurs pratiques, ce qu’ils aimeraient dire aux futurs personnes qui vont faire des mises en situation…',
    expectations: '',
    todo: [
      {
        titlePart: 'Documents ressources',
        help: 'Vous pouvez utiliser les documents suivants pour animer le rendez-vous...',
        titleTodo: 'Document à rédiger ',
        description: 'Vous pouvez noter des éléments de synthèse, des points à retenir, des éléments de décision, des éléments de planification, ...',
        ressources: [{ name: 'Fiche support bilan', href: '' }],
        actions: [
          { name: 'Déposer', href: '' },
          { name: 'Rédiger en ligne', href: '' }
        ]
      }]
  },
]
const route = useRoute();
const relevantTrainingAction = ref([]);
const trainingActionId = ref(route.params.id);
const startDate = ref(null);
const endDate = ref(null);
let profilesRelated = ref([]);
const tabs = [
  { name: 'Présentation', href: '#intro', current: false },
  { name: 'Avant', href: '#before', current: false },
  { name: 'Après', href: '#after', current: false },
]

const files = ref([
  {
    name: 'Mener un entretien réflexif amont',
    description: 'Quelques conseils pour être à l\'aise',
    type: 'pdf',
  },
  {
    name: 'Vidéo d\'un entretien réflexif',
    description: 'Description du document 2',
    type: 'video',
  }
]);

const filesAval = ref([
  {
    name: 'Mener un entretien réflexif aval',
    description: 'Quelques conseils',
    type: 'pdf',
  },
  {
    name: 'Vidéo d\'un entretien réflexif',
    description: 'Description du document 2',
    type: 'video',
  }
]);
onMounted(async () => {

});

</script>
<style scoped>
b {
  @apply font-medium text-black;
  font-weight: 600;
  color: #000;
}

.bold {
  font-weight: 600;
  color: #000;
}

p {
  @apply mb-5;
}
</style>
