<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <ConfirmationDialog @confirm="removeItem(idTypeToEdit, typeSelected)" v-if="displayConfirmDeleteDialog"
    title="Supprimer ce dossier" message="Voulez-vous vraiement supprimer ce dossier ?" primaryActionLabel="Supprimer"
    secondaryActionLabel="Annuler" @close="displayConfirmDeleteDialog = false" />

  <AddDocument v-if="displayEditFile" @close="displayEditFile = false" @confirm="$emit('update'); typeSelected = []"
    :type="typeSelected" :document="fileSelected" :key="componentKey"
    :no_action="!folder.training_action_id ? true : false" />
  <AddFolder v-if="displayEditFolder" @close="displayEditFolder = false" @confirm="$emit('update'); typeSelected = []"
    :training_action_id="folder.training_action_id" :type="typeSelected" :folder="folderSelected" :key="componentKey"
    :no_action="!folder.training_action_id ? true : false" />

  <ModalMoveFoldersAndDocuments v-if="displayModalMoveItems" @close="displayModalMoveItems = false"
    :training_action="folder?.training_action" @confirm="$emit('update'); typeSelected = []" folders=""
    :action="folder.training_action_id" :folder="folderSelected" :document="fileSelected" :type="typeSelected"
    :key="componentKey" />
  <div class="bg-white rounded-3xl pt-5 max-w-[1400px] mx-auto  px-4 sm:px-6 lg:px-6"
    style="min-height: calc( 100vh - 400px );">
    <div class="" style="min-height: calc( 100vh - 400px );">

      <div class="bg-white">
        <div class="md:flex flex-wrap items-center mb-10 space-y-3">
          <BreadcrumbDocument :item="folder.breadcrumb" class="mr-2" /> <button class="btn-primary md:ml-auto"
            v-if="folder.permissions?.write" @click="editFolder('edition', folder)">Modifier le dossier</button>
        </div>
        <div class="w-full">
          <div class="2xl:col-span-4 sm:col-span-2">
            <div class="card">
              <div class="flex flex-col">
                <div class="overflow-x-auto">
                  <div class="inline-block min-w-full align-middle">
                    <div class="overflow-hidden">
                      <div class="w-full grid 2xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6">
                        <div class="card shadow text-left" v-for="folder in relevantFolders.children" :key="folder.id">
                          <div class="p-5">
                            <div class="space-y-4 text-gray-600 dark:text-gray-300">
                              <div class="flex items-start relative gap-5">

                                <router-link :to="'/documents/dossier/' + folder.id"> <button
                                    class="flex items-center gap-3 text-left">
                                    <div class="h-14 w-14">
                                      <span class="flex h-full w-full items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                          stroke-width="1" stroke="currentColor" class="size-20 stroke-indigo-500">
                                          <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                                        </svg>

                                      </span>
                                    </div>
                                    <div class="space-y-1">
                                      <p class="font-semibold text-base text-left leading-5">{{ folder.name }}</p>
                                    </div>
                                  </button></router-link>
                                <div class="ml-auto flex flex-none items-center gap-x-4"
                                  v-if="folder.permissions?.write || currentProfile?.role.name === 'Administrateur·trice'">
                                  <Menu as="div" class="relative flex-none">
                                    <MenuButton class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                      <span class="sr-only">Open options</span>
                                      <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
                                    </MenuButton>
                                    <transition enter-active-class="transition ease-out duration-100"
                                      enter-from-class="transform opacity-0 scale-95"
                                      enter-to-class="transform opacity-100 scale-100"
                                      leave-active-class="transition ease-in duration-75"
                                      leave-from-class="transform opacity-100 scale-100"
                                      leave-to-class="transform opacity-0 scale-95">
                                      <MenuItems
                                        class="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        <MenuItem v-slot="{ active }"
                                          v-if="folder.permissions?.write || currentProfile?.role.name === 'Administrateur·trice'">
                                        <a href="#" @click="editFolder('edition', folder)"
                                          :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">Editer<span
                                            class="sr-only">, {{ folder.name }}</span></a>
                                        </MenuItem>
                                        <MenuItem v-slot="{ active }"
                                          v-if="folder.permissions?.delete || currentProfile?.role.name === 'Administrateur·trice'">
                                        <a href="#" @click="moveFolder(folder, 'folder')"
                                          :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">Déplacer<span
                                            class="sr-only">, {{ folder.name }}</span></a>
                                        </MenuItem>

                                        <MenuItem v-slot="{ active }"
                                          v-if="folder.permissions?.delete || currentProfile?.role.name === 'Administrateur·trice'">
                                        <a href="#"
                                          @click.prevent="displayConfirmDeleteDialog = true; idTypeToEdit = folder.id; typeSelected = 'folder'"
                                          :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">Supprimer<span
                                            class="sr-only">, {{ folder.name }}</span></a>
                                        </MenuItem>
                                      </MenuItems>
                                    </transition>
                                  </Menu>
                                </div>
                              </div>
                              <div class="flex items-center gap-2" v-if="folder.documents?.length > 0">
                                <span class="p-0.5 bg-gray-600 rounded-full"></span>
                                <p class="text-sm">{{ folder.documents?.length }} document(s)</p>
                              </div>
                            </div>
                          </div> <!-- end card body -->
                        </div>
                        <div class="card shadow text-left flex" v-for="document in relevantFolders.documents"
                          :key="document.id">
                          <div class="p-5 my-auto w-full">
                            <div class="space-y-4 text-gray-600 dark:text-gray-300">
                              <div class="flex items-start relative gap-5">

                                <router-link :to="'/documents/' + document.id"> <button
                                    class="flex items-center gap-3 text-left">
                                    <div class="h-10 w-10">
                                      <span class="flex h-full w-full items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                          stroke-width="1" stroke="currentColor" class="size-20 stroke-teal-500">
                                          <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                        </svg>

                                      </span>
                                    </div>
                                    <div class="space-y-1">
                                      <p class="font-semibold text-base  leading-5">{{ document.name }}</p>
                                    </div>
                                  </button></router-link>

                                <div class="ml-auto flex flex-none items-center gap-x-4"
                                  v-if="document.permissions?.write || currentProfile?.role.name === 'Administrateur·trice'">
                                  <Menu as="div" class="relative flex-none">
                                    <MenuButton class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                      <span class="sr-only">Open options</span>
                                      <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
                                    </MenuButton>
                                    <transition enter-active-class="transition ease-out duration-100"
                                      enter-from-class="transform opacity-0 scale-95"
                                      enter-to-class="transform opacity-100 scale-100"
                                      leave-active-class="transition ease-in duration-75"
                                      leave-from-class="transform opacity-100 scale-100"
                                      leave-to-class="transform opacity-0 scale-95">
                                      <MenuItems
                                        class="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        <MenuItem v-slot="{ active }">
                                        <a href="#" @click.prevent="editFile('edition', document)"
                                          :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">Editer<span
                                            class="sr-only">, {{ folder.name }}</span></a>
                                        </MenuItem>
                                        <MenuItem v-slot="{ active }"
                                          v-if="folder.permissions?.delete || currentProfile?.role.name === 'Administrateur·trice'">
                                        <a href="#" @click="moveFolder(document, 'document')"
                                          :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">Déplacer<span
                                            class="sr-only">, {{ folder.name }}</span></a>
                                        </MenuItem>
                                        <MenuItem v-slot="{ active }"
                                          v-if="folder.permissions?.delete || currentProfile?.role.name === 'Administrateur·trice'">
                                        <a href="#"
                                          @click.prevent="displayConfirmDeleteDialog = true; idTypeToEdit = document.id; typeSelected = 'document'"
                                          :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">Supprimer<span
                                            class="sr-only">, {{ folder.name }}</span></a>
                                        </MenuItem>
                                      </MenuItems>
                                    </transition>
                                  </Menu>
                                </div>
                              </div>
                            </div>
                          </div> <!-- end card body -->
                        </div>

                      </div>
                      <div v-if="folder.permissions?.write" class="md:flex gap-4">
                        <button type="button" @click.prevent="editFolder('creation')" class="mb-4 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center
              hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1"
                            stroke="currentColor" class="mx-auto h-12 w-12  text-slate-400">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                          </svg>

                          <span class="mt-2 block text-sm font-medium  text-slate-400">Ajouter un dossier</span>
                        </button>
                        <button type="button" @click.prevent="editFile('creation')" class="mb-4 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center
              hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">

                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1"
                            stroke="currentColor" class="mx-auto h-12 w-12  text-slate-400">
                            <path stroke-linecap=" round" stroke-linejoin="round"
                              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                          </svg>

                          <span class="mt-2 block text-sm font-medium  text-slate-400">Ajouter un document</span>
                        </button>
                      </div>
                      <div v-else class="mb-10"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, inject } from 'vue';
import { useRoute } from 'vue-router';
import client from '@/api/client';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid';
import AddDocument from '@/components/files/AddDocument.vue';
import AddFolder from '@/components/files/AddFolder.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import BreadcrumbDocument from '@/components/files/BreadcrumbDocument.vue';
import ModalMoveFoldersAndDocuments from '@/components/files/ModalMoveFoldersAndDocuments.vue';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);

const route = useRoute();
const displayEditFile = ref(false);
let typeSelected = ref(null);
let componentKey = ref(0);
let fileSelected = ref(null);
const idTypeToEdit = ref(null);

const displayConfirmDeleteDialog = ref(false);
const displayEditFolder = ref(false);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const folderSelected = ref([]);
const displayModalMoveItems = ref(false);

const props = defineProps({
  folder: [Object, Array],
});

const emit = defineEmits(['update']);

const relevantFolders = ref([]);
const removeItem = async (id, type) => {
  if (type === 'folder') {
    removeFolder(id);
  } else {
    removeFile(id);
  }
}

const removeFile = async (id) => {
  displayConfirmDeleteDialog.value = false;
  try {
    const response = await client.delete(`/api/documents/${id}`);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été supprimé';
    notificationMessage.value = '';
    notificationMessage.value += response.data.message;
    emit('update');
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Creation Error';
    notificationMessage.value = `Failed to create event ${error.response && error.response.data.message ? error.response.data.message : ''}`;
  }
}

const removeFolder = async (id) => {
  displayConfirmDeleteDialog.value = false;
  try {
    const response = await client.delete(`/api/folders/${id}`);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été supprimé';
    notificationMessage.value = '';
    emit('update');
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Erreur lors de la suppression';
    notificationMessage.value = ` ${error.response.data.error}`;
  }
}

const editFolder = (type, folder) => {
  typeSelected.value = type;
  if (type === 'edition') {
    folderSelected.value = folder;
  } else {
    folderSelected.value = null;
  }
  componentKey.value += 1;
  displayEditFolder.value = true;
}

const editFile = (type, document) => {
  typeSelected.value = type;
  fileSelected.value = document;
  componentKey.value += 1;
  displayEditFile.value = true;
}

const moveFolder = (item, type) => {
  if (type === 'folder') {
    folderSelected.value = item;
  } else {
    fileSelected.value = item;
  }
  typeSelected.value = type;
  console.log(item, type);
  displayModalMoveItems.value = true;
  componentKey.value += 1;
}

onMounted(() => {
  relevantFolders.value = props.folder;
});
</script>
