<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <h3 class="text-sm font-medium leading-6 text-gray-900">Partager avec</h3>
  <div class="mt-2 mb-10">
    <div class="flex space-x-2 items-center">

      <button v-for="person in users" :key="person.email"
        @click.prevent="displayUserSelect = true; selectedPerson = person; selectPerson(person)"
        class="relative rounded-full hover:opacity-75">
        <img class="inline-block h-8 w-8 rounded-full" :src="person.profile_picture_url" :alt="person.name"
          v-if="person.profile_picture_url" />
        <UserCircleIcon v-else class="h-8 w-8 text-gray-200" aria-hidden=" true" />
      </button>
      <button type="button" @click.prevent="selectedPerson = null; displayUserSelect = true" v-if="!displayUserSelect"
        class="relative inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-dashed border-gray-200 bg-white text-gray-400 hover:border-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        <span class="absolute -inset-2" />
        <span class="sr-only">Ajouter un utilisateur</span>
        <PlusIcon class="h-5 w-5" aria-hidden="true" />
      </button>
      <button type="button" @click.prevent="selectedPerson = null; displayUserSelect = false" v-if="displayUserSelect"
        class="relative inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-dashed border-gray-200 bg-white text-gray-400 hover:border-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        <span class="absolute -inset-2" />
        <span class="sr-only">Ajouter un utilisateur</span>
        <XMarkIcon class="h-5 w-5" aria-hidden="true" />
      </button>
      <button @click.prevent="displayList = !displayList" class="btn-read inline">Voir la liste</button>
    </div>
  </div>
  <div v-if="displayList" class="py-5 divide-y space-y-2">
    <button v-for="person in users" :key="person.email" class="block pt-2 w-full text-left"
      @click.prevent="displayUserSelect = true; selectedPerson = person; selectPerson(person)">
      {{ person.name }} - <span class="roleBadge bg" v-if="person.access_level === 'read'">lecture
        seule</span><span class="roleBadge bg-role3" v-if="person.access_level === 'write'">écriture</span><span
        class="roleBadge bg-role4" v-if="person.access_level === 'delete'">suppression</span>
    </button>
  </div>
  <div class="mx-auto max-w-md sm:max-w-3xl p-10 shadow rounded" v-if="displayUserSelect">
    <div>
      <div class="text-center">
        <h2 class="mt-2 text-base font-semibold leading-6 text-gray-900">Rechercher une personne avec qui partager</h2>
      </div>
      <form class="mt-6 sm:flex sm:items-center" action="#">
        <label for="emails" class="sr-only">Email addresses</label>
        <div class="grid grid-cols-1 sm:flex-auto">
          <input type="text" name="emails" id="emails"
            @input="query = $event.target.value; searchProfile($event.target.value)" @blur="query = ''"
            class="peer relative col-start-1 row-start-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="entrez un nom" />
          <div
            class="col-start-1 col-end-3 row-start-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 peer-focus:ring-2 peer-focus:ring-indigo-600"
            aria-hidden="true" />
        </div>
      </form>
    </div>
    <div v-if="attachedPeopleToStudentTraining">
      <div class="mt-10" v-if="filteredPeople.length < 1 && !selectedPerson">
        <h3 class="text-sm font-medium text-gray-500">Ces utilisateurs peuvent vous intéresser</h3>
        <ul role="list" class="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
          <li v-for="(person, personIdx) in people" :key="personIdx">
            <button type="button" @click.prevent="selectPerson(person)"
              class="group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span class="flex min-w-0 flex-1 items-center space-x-3">
                <span class="block flex-shrink-0">
                  <img class="inline-block h-8 w-8 rounded-full" :src="person.profile_picture_url" :alt="person.name"
                    v-if="person.profile_picture_url" />
                  <UserCircleIcon v-else class="h-8 w-8 text-gray-200" aria-hidden=" true" />
                </span>
                <span class="block min-w-0 flex-1">
                  <span class="block text-sm font-medium text-gray-900">{{ person.name }}</span>
                  <span class="block text-sm font-medium text-gray-500">{{ person.role }}</span>
                </span>
              </span>
              <span class="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
                <PlusIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="mt-10" v-if="filteredPeople.length > 0 && !selectedPerson">
      <h3 class="text-sm font-medium text-gray-500">Utilisateurs trouvés</h3>
      <ul role="list" class="mt-4">
        <li v-for="(person, personIdx) in filteredPeople" :key="personIdx">
          <button type="button" @click.prevent="selectPerson(person)"
            class="group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mb-2">
            <span class="flex min-w-0 flex-1 items-center space-x-3">
              <span class="block flex-shrink-0">
                <img class="inline-block h-8 w-8 rounded-full" :src="person.profile_picture_url" :alt="person.name"
                  v-if="person.profile_picture_url" />
                <UserCircleIcon v-else class="h-8 w-8 text-gray-200" aria-hidden=" true" />
              </span>
              <span class="block min-w-0 flex-1">
                <span class="block text-sm font-medium text-gray-900">{{ person.user?.name }}</span>
                <span class="block text-sm font-medium text-gray-500">{{ person.role?.name }}</span>
              </span>
            </span>
            <span class="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">

              <PlusIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />

            </span>
          </button>
        </li>
      </ul>
    </div>
    <div v-if="selectedPerson">
      <div
        class="mt-4 group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mb-2">
        <span class="flex min-w-0 flex-1 items-center space-x-3">
          <span class="block flex-shrink-0">
            <img class="inline-block h-8 w-8 rounded-full" :src="selectedPerson.profile_picture_url"
              :alt="selectedPerson.name" v-if="selectedPerson.profile_picture_url" />
            <UserCircleIcon v-else class="h-8 w-8 text-gray-200" aria-hidden=" true" />
          </span>
          <span class="block min-w-0 flex-1">
            <span class="block text-sm font-medium text-gray-900">{{ selectedPerson.user?.name }} {{
              selectedPerson.name }}</span>
            <span class="block text-sm font-medium text-gray-500"><span v-if="selectedPerson.role?.name">{{
              selectedPerson.role?.name }}</span><span v-else>{{
                  selectedPerson.role }}</span></span>
          </span>
        </span>
        <div class="ml-auto">
          <div class="flex items-center">
            <div class="flex h-6 items-center mr-3">
              <input id="read" name="comments" type="checkbox" v-model="selectedPerson.read"
                :disabled="!canMakeUnvisible" :checked="selectedPerson?.access_level === 'read'"
                @change="accessUser('read')"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"
                :key="componentKey" />
              <label for="">lecture</label>
            </div>
            <div class="flex h-6 items-center mr-3">
              <input id="write" name="comments" type="checkbox" v-model="selectedPerson.write"
                @change="accessUser('write')" :checked="selectedPerson?.access_level === 'write'"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"
                :key="componentKey" />
              <label for="">écriture</label>
            </div>
            <div class="flex h-6 items-center mr-3">
              <input id="delete" name="comments" type="checkbox" v-model="selectedPerson.delete"
                @change="accessUser('delete')" :checked="selectedPerson?.access_level === 'delete'"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"
                :key="componentKey" />
              <label for="">supression</label>
            </div>
            <button @click.prevent="detachUser()" @keyup="detachUser" class="btn-read"
              v-if="canMakeUnvisible">retirer</button>
          </div>
        </div>
        <button type="button" class="btn-primary !rounded-full hover:!bg-indigo-900 hover:!text-white"
          v-if="selectedPerson.user_id" @click.prevent="attachUser(true)">
          ajouter
        </button>
        <button type="button" class="btn-primary !rounded-full hover:!bg-indigo-900 hover:!text-white" v-else
          @click.prevent="attachUser()">
          modifier
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue'
import { PlusIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import client from '@/api/client';
import { debounce } from 'lodash';
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import { UserCircleIcon } from '@heroicons/vue/24/solid';

const props = defineProps({
  users: [Array, Object],
  type: String,
  item: [String, Number],
  makeUnvisible: {
    Boolean,
    default: true,
  },
  action: {
    type: Number,
    default: null,
  }
})
const emit = defineEmits(['update']);

const filteredPeople = ref([]);
const displayUserSelect = ref(false);
const displayRights = ref(false);
const selectedPerson = ref(null);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const users = ref([]);
const typeSelected = ref('read');
const isNewUser = ref(true);
const attachedPeopleToStudentTraining = ref(false);
const displayList = ref(false);
const canMakeUnvisible = ref(true);

let componentKey = ref(0);

const people = [
]
const selectPerson = (person) => {
  isNewUser.value = true;
  selectedPerson.value = person;
  displayRights.value = true;
  if (selectedPerson.value.access_level) {
    accessUser(selectedPerson.value.access_level, true);
    isNewUser.value = false;
  }
}

const accessUser = (type, first) => {
  if (type === 'delete') {
    selectedPerson.value.read = true;
    selectedPerson.value.write = true;
    if (first) { selectedPerson.value.delete = true };
  } else if (type === 'write') {
    selectedPerson.value.read = true;
    if (first) { selectedPerson.value.write = true; }
    selectedPerson.value.delete = false;
  } else if (type === 'read') {
    if (first) { selectedPerson.value.read = true };
    selectedPerson.value.write = false;
    selectedPerson.value.delete = false;
  }
  componentKey += 1;
  if (selectedPerson.value.access_level) {
    isNewUser.value = false;
  }
}

const attachUser = async () => {
  let userId = null;
  if (selectedPerson.value.user_id) {
    userId = selectedPerson.value.user_id;
  } else {
    userId = selectedPerson.value.id;
  }
  if (selectedPerson.value.delete === true) {
    typeSelected.value = 'delete';
  } else if (selectedPerson.value.write === true) {
    typeSelected.value = 'write';
  } else if (selectedPerson.value.read === true) {
    typeSelected.value = 'read';
  }
  let urlws = '';
  if (isNewUser.value) {
    if (props.type === 'folder') {
      urlws = `/api/folders/${props.item}/share`;
    } else if (props.type === 'document') {
      urlws = `/api/documents/${props.item}/share`;
    }
    try {
      const response = await client.post(`${urlws}`, {
        user_id: userId,
        access_level: typeSelected.value,
      });
      showNotification.value = true;
      setTimeout(() => { showNotification.value = false; }, 3000);
      notificationType.value = 'success';
      notificationTitle.value = 'Bravo, l\'utilisateur a bien été ajouté';
      notificationMessage.value = response.data.message;
      selectedPerson.value = null;
      getUsers();
    } catch (error) {
      showNotification.value = true;
      notificationType.value = 'error';
      notificationTitle.value = 'Désolé, une erreur est survenue';
    }
  } else {
    if (props.type === 'folder') {
      urlws = `/api/folders/${props.item}/update-access`;
    } else if (props.type === 'document') {
      urlws = `/api/documents/${props.item}/update-access`;
    }
    try {
      const response = await client.put(`${urlws}`, {
        user_id: selectedPerson.value.id,
        access_level: typeSelected.value,
      });
      showNotification.value = true;
      setTimeout(() => { showNotification.value = false; }, 3000);
      notificationType.value = 'success';
      notificationTitle.value = 'Bravo,';
      notificationMessage.value = response.data.message;
      selectedPerson.value = null;
      filteredPeople.value = [];
      getUsers();
    } catch (error) {
      showNotification.value = true;
      notificationType.value = 'error';
      notificationTitle.value = 'Désolé, une erreur est survenue';
    }
  }

}

const searchProfile = debounce(async (keyword) => {
  try {
    const response = await client.get(`api/profiles-search?name=${keyword}&training_action_id=${props.action}`);
    filteredPeople.value = response.data;
  } catch (error) {

    filteredPeople.value = [];
  }
}, 600);

const getUsers = async () => {
  let urlws = '';
  if (props.type === 'document') {
    urlws = `/api/documents/${props.item}/users`;
  } else if (props.type === 'folder') {
    urlws = `api/folders/${props.item}/users`;
  }
  try {
    const response = await client.get(`${urlws}`);
    users.value = response.data;
  } catch (error) {
    people.value = [];
  }
}

const detachUser = async () => {
  let urlws = '';
  if (props.type === 'document') {
    urlws = `/api/documents/${props.item}/remove-access`;
  } else if (props.type === 'folder') {
    urlws = `api/folders/${props.item}/remove-access`;
  }
  try {
    const response = await client.delete(`${urlws}`, {
      // user_id: 2,
      data: {
        user_id: selectedPerson.value.id,
      },
    });
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'utilisateur a bien été supprimé';
    notificationMessage.value += '';
    selectedPerson.value = null;
    getUsers();
  } catch (error) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Désolé, une erreur est survenue';
  }
}

onMounted(() => {
  if (!props.makeUnvisible) {
    canMakeUnvisible.value = false;
  }
  if (props.item) {
    getUsers();
  }
});
</script>
